import { CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

import { collection, deleteDoc, doc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";

const UpdateItem = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false)
  const [deleteId, setDeleteId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  let fetchdata = async () => {
    setIsLoading(true);
    let q;

    q = query(collection(db, "items"));

    const snapshot = await getDocs(q);

    let data = [];
    snapshot.forEach((doc) => {
      let snapshotObject = { id: doc.id, ...doc.data() };
      data.push(snapshotObject);
    });
    setProducts(data);
    setIsLoading(false);
    console.log(data);
  };
  const deleteData = async () => {
    // console.log(deleteId);
    setIsLoading(true);
    await deleteDoc(doc(db, "items", deleteId));
    fetchdata();
    setIsLoading(false);
    setDeleteId("");
  };

  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <Flex flexWrap={"wrap"} justifyContent={"space-around"}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {products.map((product) => {
            return (
              <Box
                maxW="sm"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                m={5}
              >
                <Image src={product.image_url} alt={product.imageAlt} />

                <Box p="6">
                  <Box display="flex" alignItems="baseline">
                    <Box
                      color="gray.500"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      fontSize="xs"
                      textTransform="uppercase"
                      ml="2"
                    >
                      {product.category}
                    </Box>
                  </Box>

                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    {product.title}
                  </Box>
                  <Box>{product.description}</Box>
                  <Flex justifyContent={"space-between"} mt={4}>
                    <Link to={`edit/${product.id}`}>
                      <Button size={"sm"} leftIcon={<EditIcon />}>
                        Edit Data
                      </Button>
                    </Link>
                    <Button
                      size={"sm"}
                      leftIcon={<DeleteIcon />}
                      onClick={() => {
                        onOpen();
                        setDeleteId(product.id);
                      }}
                    >
                      Delete Data
                    </Button>
                  </Flex>
                </Box>
              </Box>
            );
          })}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Delete Item</ModalHeader>
              <ModalCloseButton />
              <ModalBody></ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={() => {
                    onClose();
                    deleteData();
                  }}
                  leftIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
                <Button
                  colorScheme={"blue"}
                  leftIcon={<CloseIcon />}
                  onClick={() => {
                    setDeleteId("");
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Flex>
  );
};

export default UpdateItem;
