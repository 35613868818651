import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import AuthContext from "./context/AuthContext";
import Admin from "./pages/Admin/Admin";
import EditItemFormControl from "./pages/Admin/EditItemFormControl";
import Login from "./pages/Admin/Login";
import Contact from "./pages/Contact/Contact";
import HomePage from "./pages/Homepage/HomePage";
function App() {
  useEffect(() => {
    AOS.init({});
    window.addEventListener("load", AOS.refresh);
  }, []);
  let { user } = useContext(AuthContext);
  let location = useLocation();
  let showNav = true;
  if (location.pathname.startsWith("/admin")) {
    showNav = false;
  }
  return (
    <div>
      {showNav ? <Navbar /> : <></>}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<Contact />} />
        {!user ? (
          <Route path="/admin" element={<Login />} />
        ) : (
          <>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/edit/:id" element={<EditItemFormControl />} />
          </>
        )}
      </Routes>
      {showNav ? <Footer /> : <></>}
    </div>
  );
}

export default App;
