import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [LoginEmail, setLoginEmail] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [Error, setError] = useState("");
  const [User, setUser] = useState({});
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [Loading, setLoading] = useState(false);

  let navigate = useNavigate();
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    setLoadingInitial(false);
  });

  const Login = async () => {
    setLoading(true);
    try {
      const User = await signInWithEmailAndPassword(
        auth,
        LoginEmail,
        LoginPassword
      );
      console.log(User);
      setError("");
    } catch (err) {
      console.log(err);
      setError(err.message);
      // console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const Logout = async () => {
    setLoading(true);
    await signOut(auth)
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
        navigate("/");
      });
  };

  let contextData = {
    user: User,
    login: Login,
    logout: Logout,
    setLoginEmail: setLoginEmail,
    setLoginPassword: setLoginPassword,
    error: Error,
    loading: Loading,
  };
  return (
    <AuthContext.Provider value={contextData}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};
