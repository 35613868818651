import { Link } from "react-router-dom";
import React from "react";
import {
  AiFillHome,
  AiOutlineMail,
  AiFillMobile,
  AiFillPhone,
} from "react-icons/ai";
import "./styles.css";
import { Facebook, Instagram } from "react-bootstrap-icons";
const Footer = () => {
  return (
    <>
      <footer class="footer">
        <div class="footer__addr">
          <h1 class="footer__logo">SHIV SHAKTI ENTERPRISES</h1>

          <p>
            Established in year 1988, Shiv Shakti Enterprises is one of the
            leading wholesale trader and manufacturer of Gas Stove Pan Support,
            LPG Gas Valve, Gas Regulator and Gas Burners etc.
          </p>
        </div>
        <ul class="footer__nav">
          <li class="nav__item">
            <h2 class="nav__title">PRODUCTS</h2>

            <ul class="nav__ul">
              <li>Pan Support</li>

              <li>Gas Lighter</li>

              <li>Gas Burner</li>
              <li>Mixing Tube</li>
              <li>Gas Stove & All parts</li>
              <Link to="/"> View All Products</Link>
            </ul>
          </li>

          <li class="nav__item nav__item--extra">
            <h2 class="nav__title">CONTACT</h2>

            <ul class="nav__ul nav__ul--extra">
              <li>
                <AiFillHome className="icon" />
                115 Sherawali Mkt, Sadar Bazar, Delhi, India
              </li>

              <li>
                <AiFillHome className="icon" />
                CW-278 Sanjay Gandhi Transport Nagar, Delhi, India
              </li>

              <li>
                <AiFillHome className="icon" />
                1424 Galli Lallu Mishan, Delhi, India
              </li>

              <li>
                <AiOutlineMail className="icon" />
                ShivSenterprises2000@gmail.com
              </li>
              <li>
                <AiFillMobile className="icon" />+ 91 8010166637
              </li>
              <li>
                <AiFillMobile className="icon" />+ 91 9868581201
              </li>
              <li>
                <AiFillPhone className="icon" />+ 011 42436635
              </li>
              <li>
                <AiFillPhone className="icon" />+ 011 42436637
              </li>

              <Link to="/contact"> Order Now</Link>
            </ul>
          </li>
        </ul>

        <div class="legal">
          <p>&copy; 2020 Copyright: SHIV SHAKTI ENTERPRISES</p>

          <span>
            <a href="https://www.facebook.com/shivshaktienterprises-101396594980163">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/shiv.shakti.enterprises/">
              <Instagram />
            </a>
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
