import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  chakra,
  ChakraProvider,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import { FaUserAlt, FaLock } from "react-icons/fa";
import AuthContext from "../../context/AuthContext";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  let { login, setLoginEmail, setLoginPassword, error, loading } =
    useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  return (
    <ChakraProvider>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        width="100wh"
        height="100vh"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar bg="teal.500" />
          <Heading textAlign={"center"}>Welcome to Admin Panel</Heading>
          <Box minW={{ base: "90%", md: "468px" }}>
            <Stack spacing={4} p="1rem">
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt />}
                  />
                  <Input
                    type="email"
                    placeholder="email address"
                    onChange={(event) => {
                      setLoginEmail(event.target.value);
                    }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaLock />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(event) => {
                      setLoginPassword(event.target.value);
                    }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button onClick={login}>Login</Button>
            </Stack>
          </Box>
          {loading ? <Spinner /> : <></>}
          {error ? (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>Error!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          ) : (
            <></>
          )}
        </Stack>
      </Flex>
    </ChakraProvider>
  );
};

export default Login;
