import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import HeaderCarousel from "../../components/Carousel/HeaderCarousel";
import "bootstrap/dist/css/bootstrap.min.css";
// import Category from "../../components/Category/Category";
import ProductCard from "../../components/ProductCard/ProductCard";
import Spinner from "../../components/Spinner/Spinner";
import { db } from "../../firebase";
import useQuery from "../../hooks/useQuery";
import "./styles.css";

const HomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [dataLoading, setDataLoading] = useState(true);
  let queryParameter = useQuery();
  let currentCategory = queryParameter.get("category");
  useEffect(() => {
    window.scrollTo(0, 0);
    let fetchdata = async () => {
      setDataLoading(true);
      let q;
      if (!currentCategory || currentCategory === "All") {
        q = query(collection(db, "items"));
      } else {
        q = query(
          collection(db, "items"),
          where("category", "==", currentCategory)
        );
      }
      const snapshot = await getDocs(q);

      let data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setProducts(data);
      setDataLoading(false);
    };
    let fetchCategories = async () => {
      const snapshot = await getDocs(collection(db, "categories"));
      let data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      data = [
        { name: "Pan Support" },
        ...data.filter((obj) => {
          return obj.name !== "Pan Support";
        }),
      ];
      console.log(data);
      setCategories(data);
    };
    fetchdata();
    fetchCategories();
  }, [currentCategory]);

  return (
    <>
      {dataLoading ? (
        <Spinner />
      ) : (
        <>
          <HeaderCarousel />
          <div className="homepage-container">
            <Navbar
              collapseOnSelect
              expand="sm"
              bg="light"
              variant="light"
              sticky="top"
            >
              <Container className="h-50 d-flex flex-wrap">
                <Navbar.Brand className="navbar-heading">
                  Categories
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="flex-wrap">
                    <LinkContainer to={`/`}>
                      <Nav.Link href="/" className={currentCategory ? ' category ' : 'category active-category' }>All</Nav.Link>
                    </LinkContainer>
                    {categories.map((category) => {
                      return (
                        <LinkContainer to={`/?category=${category.name}`}>
                          <Nav.Link
                            className={
                              currentCategory === category.name
                                ? "category active-category"
                                : "category"
                            }
                          >
                            {category.name}
                          </Nav.Link>
                        </LinkContainer>
                      );
                    })}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <div className="products">
              {products.map((product) => {
                return (
                  <ProductCard
                    key={product.id}
                    title={product.title}
                    description={product.description}
                    category={product.category}
                    imageUrl={product.image_url}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomePage;
