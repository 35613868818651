import React, { useEffect } from "react";
import  AOS  from "aos";
import { Link } from "react-router-dom";
import "./style.css";
const ProductCard = ({title,description,category,imageUrl}) => {
  useEffect(() => {
    AOS.init({
    });
    window.addEventListener("load", AOS.refresh);
  }, []);
  return (
    <div class="el-wrapper" data-aos="fade-right">
      <div class="box-up">
        <img
          class="img"
          src={imageUrl}
          alt=""
        />
        <div class="img-info">
          <div class="info-inner">
            <span class="p-name">{category}</span>
            <span class="p-company">{title}</span>
          </div>
          <div class="a-size">
            {description}
          </div>
        </div>
      </div>

      <div class="box-down">
        <div class="h-bg">
          <div class="h-bg-inner"></div>
        </div>

        <Link class="cart" to="/contact">
          <span class="price">Order Now</span>
          <span class="add-to-cart">
            <span class="txt">Order Now</span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
