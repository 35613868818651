import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Textarea,
  ChakraProvider,
  Image,
  Select,
  Progress,
} from "@chakra-ui/react";
import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { db, storageBucket } from "../../firebase";

const EditItemFormControl = () => {
  let { id } = useParams();
  const [error, setError] = useState({});
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [event, setEvent] = useState(null);
  let handleSubmit = (e) => {
    e.preventDefault();
    setEvent(e);
    console.log(e.target.name.value);
    let title = e.target.name.value ? e.target.name.value : product.title;
    console.log(title);
    let description = e.target.description
      ? e.target.description.value
      : product.description;
    let category = e.target.category
      ? e.target.category.value
      : product.category;
    console.log(description);
    let file = e.target.image.files[0];
    setIsSubmitting(true);

    if (!file) {
      let Data = {
        title: title,
        category: category,
        description: description,
        image_url: product.image_url,
      };
      sendData(Data);
      return;
    }
    uploadFiles(file);
    let Data = {
      title: title,
      category: category,
      description: description,
    };
    setData(Data);
  };
  let uploadFiles = (file) => {
    console.log("started uploading");
    setIsUploading(true);
    if (!file) return;

    const storageref = ref(storageBucket, `/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        setError({
          submissionError: `Error In Uploading Photo to Database, Error - ${err}`,
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          setIsUploading(false);
        });
      }
    );
  };
  let sendData = async (data) => {
    try {
      await setDoc(doc(db, "items", id), data);
      setIsSuccesful(true);
    } catch (err) {
      setIsError(true);
      setError({ submissionError: "Error in Adding Data to Database" });
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    console.log(progress);
    let fetchdata = async () => {
      setIsLoading(true);
      const snapshot = await getDoc(doc(db, "items", id));
      setProduct(snapshot.data());
      setIsLoading(false);
      console.log(data);
    };
    let fetchCategories = async () => {
      const snapshot = await getDocs(collection(db, "categories"));
      let data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setCategories(data);
    };
    fetchdata();
    fetchCategories();
    // console.log(url);
  }, []);
  useEffect(() => {
    console.log(url);
    if (!isUploading && progress > 0 && isSubmitting) {
      let newData = { ...data, image_url: url };
      console.log(url);
      console.log(newData);
      setData(newData);
      sendData(newData);
      setProgress(0);
      setIsSuccesful(true);
      event.target.reset();
      setEvent(null);
    }
  }, [progress, url, data, isUploading, isSubmitting]);

  return (
    <ChakraProvider>
      <Flex align={"center"} direction={"column"}>
        <Heading mt={50}>Admin Panel</Heading>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Flex
              mt={100}
              justifyContent={"space-evenly"}
              direction={{ base: "column", lg: "row" }}
            >
              <Image
                src={product.image_url}
                alt={product.imageAlt}
                height={250}
                mr={100}
                alignSelf={"center"}
              />
              <form onSubmit={(e) => handleSubmit(e)}>
                <Box marginRight={10}>
                  <Heading textAlign={"center"} mb={10}>
                    Edit Item
                  </Heading>
                  <FormControl isInvalid={isError}>
                    <FormLabel htmlFor="name" textAlign={"center"}>
                      Name of Item
                    </FormLabel>
                    <Input
                      color={"gray.800"}
                      id="name"
                      placeholder={product.title}
                      defaultValue={product.title}
                    />
                    <FormErrorMessage>
                      {error?.nameLengthError}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={isError}>
                    <FormLabel htmlFor="email" textAlign={"center"}>
                      Category
                    </FormLabel>
                    <Select
                      placeholder={product.category}
                      defaultValue={product.category}
                      id="category"
                      name="category"
                    >
                      {categories.map((category) => {
                        return (
                          <option value={category.name}>{category.name}</option>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl isInvalid={isError}>
                    <FormLabel htmlFor="email" textAlign={"center"}>
                      Description
                    </FormLabel>
                    <Textarea
                      id="description"
                      name="description"
                      color={"gray.800"}
                      placeholder={product.description}
                      defaultValue={product.description}
                    />
                  </FormControl>
                  <FormControl isInvalid={isError}>
                    <FormLabel htmlFor="description" textAlign={"center"}>
                      Image
                    </FormLabel>
                    <Input color={"gray.800"} type={"file"} id="image" />
                  </FormControl>
                </Box>

                <Flex justifyContent={"center"}>
                  <Button
                    mt={4}
                    mb={4}
                    colorScheme="teal"
                    isLoading={isSubmitting}
                    type="submit"
                    textAlign={"center"}
                  >
                    Submit
                  </Button>
                </Flex>
                {isUploading ? <Progress value={progress} hasStripe /> : <></>}
                {error.submissionError ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>{error.submissionError}</AlertDescription>
                  </Alert>
                ) : (
                  <></>
                )}
                {isSuccesful ? (
                  <Alert status="success">
                    <AlertIcon />
                    Data uploaded to the server. Fire on!
                  </Alert>
                ) : (
                  <></>
                )}
              </form>
            </Flex>
          </>
        )}
      </Flex>
    </ChakraProvider>
  );
};

export default EditItemFormControl;
