import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
const Navbar = () => {
  const [checked, setChecked] = useState(false);
  return (
    <div className="navbar_wrapper">
      <div className="image-wrapper">
        <Link to="/">
          <img src="/SSenterprise_logo_new.jpeg" alt="SSE logo" />
        </Link>
      </div>

      <input
        type="checkbox"
        id="active"
        checked={checked}
        onClick={() => {
          setChecked(!checked);
        }}
      />
      <label for="active" className="menu-btn">
        <span></span>
      </label>
      <label for="active" className="close-btn"></label>
      <div className="wrapper">
        <ul>
          <li>
            <Link
              to="/"
              onClick={() => {
                setChecked(false);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              onClick={() => {
                setChecked(false);
              }}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
