import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Textarea,
  Progress,
  Select,
} from "@chakra-ui/react";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
// import uniqueSlug from "unique-slug";
import { db, storageBucket } from "../../firebase";
import AddCategory from "./AddCategory";

const AddItemFormControl = () => {
  const [error, setError] = useState({});
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState("");
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [event, setEvent] = useState(null)
  let handleSubmit = (e) => {
    e.preventDefault();
    setEvent(e)
    let title = e.target.name.value;
    let description = e.target.description.value;
    let category = e.target.category.value;
    let file = e.target.image.files[0];
    setIsSubmitting(true);
    uploadFiles(file);
    let Data = {
      title: title,
      category: category,
      description: description,
    };
    setData(Data);
    // sendData(data, e);
  };
  let uploadFiles = (file) => {
    console.log("started uploading");
    setIsUploading(true);
    if (!file) return;

    const storageref = ref(storageBucket, `/images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        setError({
          submissionError: `Cannot Upload File to firebase. Error - ${err}`,
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrl(url);
          setIsUploading(false);
        });
      }
    );
  };
  let sendData = async (data) => {
    try {
      await addDoc(collection(db, "items"), data);
    } catch (err) {
      setIsError(true);
      setError({ submissionError: "Error in Adding Data to Database" });
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    let fetchCategories = async () => {
      const snapshot = await getDocs(collection(db, "categories"));
      let data = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      data = [
        { name: "Pan Support" },
        ...data.filter((obj) => {
          return obj.name !== "Pan Support";
        }),
      ];
      console.log(data);
      setCategories(data);
    };
    fetchCategories();

    console.log(progress);

    if (!isUploading && progress > 0 && isSubmitting) {
      let newData = { ...data, image_url: url };
      console.log(newData);
      setData(newData);
      sendData(newData);
      setProgress(0);
      setIsSuccesful(true);
      event.target.reset()
      setEvent(null)
    }
  }, [progress, url, data, isUploading, isSubmitting]);
  return (
    <Flex direction={"column"}>
      <AddCategory />

      <form onSubmit={(e) => handleSubmit(e)}>
        <Box marginRight={10}>
          <Heading textAlign={"center"} mb={10}>
            Add Item
          </Heading>
          <FormControl isInvalid={isError} isRequired>
            <FormLabel htmlFor="name" textAlign={"center"}>
              Name of Item
            </FormLabel>
            <Input id="name" placeholder="Name" required color={"gray.800"} />
            <FormErrorMessage>{error?.nameLengthError}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={isError} isRequired>
            <FormLabel htmlFor="email" textAlign={"center"}>
              Category
            </FormLabel>
            <Select placeholder="Select Category" id="category" name="category">
              {categories.map((category) => {
                return <option value={category.name}>{category.name}</option>;
              })}
            </Select>
          </FormControl>

          <FormControl isInvalid={isError}>
            <FormErrorMessage>{error?.phoneLengthError}</FormErrorMessage>
            <FormLabel htmlFor="description" textAlign={"center"}>
              Description
            </FormLabel>
            <Textarea
              placeholder="Item Description"
              id="description"
              required
              color={"gray.800"}
            />
          </FormControl>
          <FormControl isInvalid={isError}>
            <FormErrorMessage>{error?.phoneLengthError}</FormErrorMessage>
            <FormLabel htmlFor="description" textAlign={"center"}>
              Image
            </FormLabel>
            <Input type={"file"} id="image" required color={"gray.800"} />
          </FormControl>
        </Box>

        <Flex justifyContent={"center"}>
          <Button
            mt={4}
            mb={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            type="submit"
            textAlign={"center"}
          >
            Submit
          </Button>
        </Flex>
        {isUploading ? <Progress value={progress} hasStripe /> : <></>}
        {error.submissionError ? (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{error.submissionError}</AlertDescription>
          </Alert>
        ) : (
          <></>
        )}
        {isSuccesful ? (
          <Alert status="success">
            <AlertIcon />
            Data uploaded to the server. Fire on!
          </Alert>
        ) : (
          <></>
        )}
      </form>
    </Flex>
  );
};

export default AddItemFormControl;
