// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBDElhEio7Oz7ES3cvxBFxGMS_E2LO5ejw",
  authDomain: "ssenterprises-bbad7.firebaseapp.com",
  projectId: "ssenterprises-bbad7",
  storageBucket: "ssenterprises-bbad7.appspot.com",
  messagingSenderId: "893748947503",
  appId: "1:893748947503:web:dd349703f6e39d77eff4da",
  measurementId: "G-LP9WYT6S07",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const storageBucket = getStorage();
const auth = getAuth();
export { db, storageBucket, auth };
