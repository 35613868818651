import React from "react";
import "./styles.css";
import Carousel from "react-bootstrap/Carousel";
import { AiFillCheckCircle } from "react-icons/ai";
const HeaderCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item as={"div"} className="text">
        <h3>Welcome to Shiv Shakti Enterprises</h3>

        <p>
          <AiFillCheckCircle /> GSTIN 07AAHPB7694L2Z7
        </p>
        <p>
          Established in year 1988, Shiv Shakti Enterprises is one of the
          leading wholesale trader and manufacturer of Gas Stove Pan Support,
          LPG Gas Valve, Gas Regulator and Gas Burners etc. All our products are
          manufactured and marketed in INDIA
        </p>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="image"
          src="/founder_dinesh_new_cropped.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Founder</h3>

          <p>Dinesh Bansal</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="image"
          src="/cofounder_shivansh_new.jpeg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Co Founder</h3>
          <p>CA Shivansh Bansal</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeaderCarousel;
