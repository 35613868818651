import {
  Button,
  ChakraProvider,
  Flex,
  Heading,
  ScaleFade,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { AddIcon, ViewIcon } from "@chakra-ui/icons";
import AddItemFormControl from "./AddItemFormControl";
import UpdateItem from "./UpdateItem";
import AuthContext from "../../context/AuthContext";

// import AddCategory from "./AddCategory";
const Admin = () => {
  const [addItemDisplay, setAddItemDisplay] = useState(false);
  const [updateItemDisplay, setUpdateItemDisplay] = useState(false);
  const [margin, setMargin] = useState(true);
  const { isOpen, onToggle } = useDisclosure();
  let { logout } = useContext(AuthContext);
  return (
    <ChakraProvider>
      <Flex justifyContent={"center"} align={"center"} flexDirection={"column"}>
        <Heading
          mt={margin ? 100 : 50}
          transition={"all 0.2s cubic-bezier(.08,.52,.52,1)"}
        >
          Admin Panel
        </Heading>
        <Flex
          mt={margin ? 100 : 50}
          transition={"all 0.2s cubic-bezier(.08,.52,.52,1)"}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Button
            leftIcon={<AddIcon />}
            m={5}
            size={"lg"}
            onClick={() => {
              setAddItemDisplay(true);
              setUpdateItemDisplay(false);
              if (addItemDisplay) {
                onToggle();
                setMargin(!margin);
              }
            }}
          >
            Add Item
          </Button>

          <Button
            m={5}
            size={"lg"}
            leftIcon={<ViewIcon />}
            onClick={() => {
              setAddItemDisplay(false);
              setUpdateItemDisplay(true);
              if (updateItemDisplay) {
                onToggle();
                setMargin(!margin);
              }
            }}
          >
            View Data
          </Button>
          <Button m={5} size={"lg"} onClick={logout}>
            Logout
          </Button>
        </Flex>
        <ScaleFade initialScale={0.9} in={isOpen}>
          {addItemDisplay ? <AddItemFormControl /> : <></>}
          {updateItemDisplay ? <UpdateItem /> : <></>}
        </ScaleFade>
      </Flex>
    </ChakraProvider>
  );
};

export default Admin;
