import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";

const AddCategory = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    let handleSubmit = async (e) =>{
        setIsSubmitting(true)
        e.preventDefault();
        let name = e.target.name.value;
        await setDoc(doc(db,"categories",name),{
            'name':name
        })
        setIsSubmitting(false)

    }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Box>
        <Heading textAlign={"center"} mb={10}>
          Add Category
        </Heading>
        <FormControl  isRequired>
          <FormLabel htmlFor="name" textAlign={"center"}>
            Name of Category
          </FormLabel>
          <Input id="name" placeholder="Name" required />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
      </Box>
      <Flex justifyContent={"center"}>
        <Button
          mt={4}
          mb={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          type="submit"
          textAlign={"center"}
        >
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default AddCategory;
